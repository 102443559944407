import React from "react"
import Layout from '../components/Layout'
import eyeChartSVG from '../data/SVG/eye-chartv3.svg'
import Fade from 'react-reveal/Fade'

export default function Practice() {
  
  return (
    <div>
      <Layout>
        
          <div className='flex-l flex-wrap-l justify-around-l items-center-l flex-m justify-around-m items-center-m w-80-l center'>

            <Fade up duration={1000} delay={500}>
              <div className="fl pa3 pa4-ns bg-white black-70 w-50-l w-50-m w-100">
                  <header className="bb b--black-70 pv4">
                    <h3 className="f3 fw7 ttu tracked lh-title mt0 mb3 avenir">Comprehensive Eye Exams</h3>
                  </header>
                  <section className="pt5 pb4">
                    <p className="lh-copy measure f4-l f5 mt0">Dr. Earl Winer utilizes state of the art equipment to provide you with the most thorough and comprehensive vision and eye health examination.  All of our equipment is computerized, integrated and linked throughout the office to improve efficiency and accuracy.<br/><br/>
                      We have the Topcon CV-5000 automated refracting system
                      We measure your current glasses with our Topcon CL-200 machine to be able to inform you of any changes to your prescription.
                      We measure the curvature of the cornea (keratometry) and evaluate the power of your eyes (autorefraction) with the Topcon KR-8800.
                      We measure the eye pressure (tonometry) using the Reichert non contact computerized tonometer which helps in the screening of glaucoma.
                      We offer digital retinal photography and OCT Imaging. <br/><br/>
                      All information for your eye exam will be stored in your electronic medical record.  Dr. Earl has been paperless for over 20 years.</p>
                  </section>
              </div>
            </Fade>
            <Fade right duration={1000} delay={1000}>
              <div className='w-50-l w-50-m w-100 fr tc'>
                <img className='filtered-blue w-40-l w-40-m w-60 pa2 ma2' src={eyeChartSVG} alt='eye chart'/>
              </div>
            </Fade>
          </div>
        
      </Layout>
    </div>
    )
}